import React from 'react';
import PropTypes from 'prop-types';
import { Heading4 } from '../../../components/Heading/Heading';
import { COLOR } from '../../../utils/constants';

function Heading({ date, children }) {
  return (
    <>
      {date && <strong>{date}</strong>}
      {children && (
        <Heading4
          color={COLOR.BLUE}
          marginTop={date ? '8px' : '48px'}
          marginBottom="16px"
        >
          {children}
        </Heading4>
      )}
    </>
  );
}

Heading.propTypes = {
  date: PropTypes.string,
  children: PropTypes.node
};

export default Heading;
