import React from 'react';
import PropTypes from 'prop-types';
import QuoteBox from '../../../components/QuoteBox/QuoteBox';

const CustomQuoteBox = ({ text }) => (
  <QuoteBox quoteText={text} addQuotes={false} />
);

CustomQuoteBox.propTypes = {
  text: PropTypes.string.isRequired
};

export default CustomQuoteBox;
