/* eslint-disable import/no-dynamic-require */
import React from 'react';
import Helmet from 'react-helmet';
import RehypeReact from 'rehype-react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Box } from '../../components/Box/Box';
import Hero from '../../components/Hero/Hero';
import ImportantInformation from '../../components/ImportantInformation/ImportantInformation';
import QuoteBox from './custom-components/QuoteBox';
import MediaContact from './custom-components/MediaContact';
import Heading from './components/Heading';
import { Heading3 } from '../../components/Heading/Heading';
import CardNews from '../../components/CardNews/CardNews';
import Section from '../../components/Section/Section';
import Layout from '../../components/layout';
import {
  MARGIN,
  COLOR,
  ALIGN,
  BREAKPOINT,
  PADDING,
  SITE_URL
} from '../../utils/constants';

const AlsoReadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT.MD}) {
    flex-direction: column;
    align-items: center;
  }
`;
const AlsoRead = styled.div`
  display: flex;
  width: 332px;
  @media (max-width: ${BREAKPOINT.MD}) {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
`;

const MediaReleaseBody = styled.div`
  a {
    color: ${COLOR.BLUE};
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 106, 198, 0.5);
    :hover {
      text-shadow: 0 0 1px ${COLOR.BLUE};
      border-bottom: 1px solid rgba(0, 106, 198, 1);
    }
  }
`;

const MediaReleaseTemplate = ({ location, data, pageContext }) => {
  const { markdownRemark } = data;
  const { allMediaReleases } = pageContext;
  const { frontmatter, htmlAst } = markdownRemark;
  const {
    title,
    date,
    firstHeading,
    alsoRead,
    heroBg,
    footnotes
  } = frontmatter;
  const canonicalUrl = `${SITE_URL}${location.pathname}`;
  const alsoReadData = (alsoRead || []).reduce((acc, alsoReadDatum) => {
    if (alsoReadDatum === null) {
      return acc;
    }

    const mediaRelease = allMediaReleases.find(
      mediaRelease =>
        mediaRelease.markdownFileName === alsoReadDatum.relativePath
    );

    if (mediaRelease) {
      acc.push({
        title: mediaRelease.title,
        path: mediaRelease.path
      });
    }

    return acc;
  }, []);
  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
      'quote-box': QuoteBox,
      'media-contact': MediaContact,
      h4: Heading,
      a: Link
    }
  }).Compiler;

  return (
    <Layout noBreadcrumb>
      <main className="navigation-spacer">
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>{`${title} | Media Releases`}</title>
          <meta
            name="description"
            content="Värde Partners, Deutsche Bank and KKR have completed the acquisition of GE Money now called Latitude Financial Services."
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <Hero
          h1={title}
          bg={require(`./images/${heroBg ? heroBg : 'media-release-hero.jpg'}`)}
          backgroundPosition="top center"
          darkBg
        />
        <Section className="bg-white">
          <Box isResponsive isBodyCopy margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}>
            <Heading date={date}>{firstHeading}</Heading>
            <MediaReleaseBody>{renderAst(htmlAst)}</MediaReleaseBody>
          </Box>
        </Section>
        {alsoReadData.length > 0 && (
          <Box backgroundColor={COLOR.BLUE_DARK}>
            <Box
              isResponsive
              isBodyCopy
              margin={`${MARGIN.M0} ${MARGIN.MAUTO} ${MARGIN.M0}`}
              padding={`${PADDING.P40} ${PADDING.P0} ${PADDING.P40}`}
            >
              <Heading3 color={COLOR.WHITE} align={ALIGN.CENTER}>
                You could also read
              </Heading3>
              <AlsoReadWrapper>
                {alsoReadData.map(({ title, path }) => (
                  <AlsoRead key={path}>
                    <CardNews
                      titleProps={{ title, isGreyBg: true, height: 120 }}
                      buttonProps={{
                        button: BUTTON_STYLE.TERTIARY,
                        href: path,
                        btnText: 'Read more'
                      }}
                    />
                  </AlsoRead>
                ))}
              </AlsoReadWrapper>
            </Box>
          </Box>
        )}
        {footnotes && (
          <ImportantInformation
            data={{
              content: {
                importantInformationSectionOne: footnotes
              }
            }}
            sectionOneColummClasses="col-10 offset-1"
          />
        )}
      </main>
    </Layout>
  );
};

export const query = graphql`
  query MediaRelease($fileAbsolutePath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      frontmatter {
        title
        date(formatString: "D MMMM YYYY")
        firstHeading
        alsoRead {
          relativePath
        }
        heroBg
        footnotes
      }
      htmlAst
    }
  }
`;

export default MediaReleaseTemplate;
