// @flow

import React from 'react';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from '@latitude/link';
import type { CardTitleProps } from '@/components/CardTitle/CardTitle';
import CardTitle from '../CardTitle/CardTitle';
import './_card-news.scss';

const component = 'card-news';

type ButtonProps = {
  btnText: string
};

type Props = {
  date?: string,
  description: string,
  maxLines: string,
  buttonProps: ButtonProps,
  titleProps: CardTitleProps,
  className: string,
  style?: CSSStyleDeclaration
};

const CardNews = (props: Props) => {
  const {
    date,
    description,
    buttonProps,
    maxLines,
    titleProps,
    className,
    style
  } = props;

  return (
    <article className={classnames(`${component}`, className)} style={style}>
      <CardTitle {...titleProps} />
      <div className={`${component}__content`}>
        <strong className={`${component}__date`}>{date}</strong>
        <div className={`${component}__text`}>
          <LinesEllipsis
            text={description}
            maxLine={maxLines ? maxLines : '5'}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>
        {buttonProps && <Link {...buttonProps}>{buttonProps.btnText}</Link>}
      </div>
    </article>
  );
};

export default CardNews;
