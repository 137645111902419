// @flow

import React from 'react';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import './_card-title.scss';

const component = 'title';

type Props = {
  isGreyBg?: boolean, //Defaults to dark blue background
  title: string,
  height?: number
};

const CardTitle = (props: Props) => {
  const { isGreyBg, title, height } = props;

  return (
    <h2
      className={classnames(
        `${component}`,
        isGreyBg ? `title__grey-bg` : `title__blue-bg`
      )}
      style={{ height }}
    >
      <LinesEllipsis
        text={title}
        maxLine="4"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
    </h2>
  );
};

export default CardTitle;
export type CardTitleProps = Props;
