import React from 'react';
import PropTypes from 'prop-types';
import './_quote-box.scss';

const QuoteBox = ({ quoteText, addQuotes = true }) => (
  <span className="quote-box text--large">
    {addQuotes ? `"${quoteText}"` : quoteText}
  </span>
);

QuoteBox.propTypes = {
  quoteText: PropTypes.string.isRequired
};

export default QuoteBox;
